<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searParkRecordList">
        <el-form
          :inline="true"
          ref="searchWrapper"
          label-position="right"
          :model="formInline"
          class="demo-form-inline"
        >
          <el-form-item :label="$t('searchModule.region')">
            <el-select
              v-model.trim="formInline.areaId"
              @change="areaChange"
              filterable
              size="15"
              placeholder="请选择"
            >
              <el-option label="全部" value=""></el-option>
              <el-option
                :label="area.areaName"
                :value="area.areaId"
                :key="area.areaId"
                v-for="area in areaList"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('searchModule.region')">
            <el-select
              v-model.trim="formInline.streetId"
              filterable
              @change="
                $refs.parkInput.setValue('');
                formInline.operationId = '';
              "
              size="15"
              placeholder="请选择"
            >
              <el-option label="全部" value=""></el-option>
              <el-option
                :label="area.areaName"
                :value="area.areaId"
                :key="area.areaId"
                v-for="area in areaList2"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Merchant_Name')">
            <el-select v-model.trim="formInline.operationId" filterable size="15">
              <el-option label="全部" value=""></el-option>
              <el-option
                :label="value.operationName"
                :value="value.operationId"
                :key="value.operationId"
                v-for="value in roleList"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('searchModule.park_name')">
            <my-component
              ref="parkInput"
              :areaIds="formInline.streetId ? formInline.streetId : formInline.areaId"
              :operationId="formInline.operationId"
              @valueChange="completeValue"
              :dataSource="2"
              slaveRelations="0,1"
            >
            </my-component>
          </el-form-item>
          <el-form-item label="停车场类型">
            <el-select v-model.trim="formInline.closedParkType" filterable placeholder="" size="10">
              <el-option label="全部" value="0"></el-option>
              <el-option
                :label="park.desc"
                :value="park.code"
                :key="park.code"
                v-for="park in parkType"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('searchModule.is_malfunction')">
            <el-select
              v-model.trim="formInline.equipmentStatus"
              filterable
              placeholder=""
              size="10"
            >
              <el-option label="全部" value=""></el-option>
              <el-option
                :label="fault.desc"
                :value="fault.code"
                :key="fault.code"
                v-for="fault in faultType"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="
              pageNum = 1;
              searParkRecordList();
            "
            :loading="loading"
            >{{ $t('button.search') }}</el-button
          >
          <el-button type="primary" icon="el-icon-delete" @click="resetForm()">{{ $t('button.reset') }}</el-button>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table v-loading="loading" :data="tableData" style="width: 100%">
          <!--<el-table-column type="index" :label="$t('list.index')" width="70"></el-table-column>-->
          <el-table-column
            align="center"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
          ></el-table-column>
          <el-table-column label="操作" width="80">
            <template slot-scope="scope">
              <el-button type="info" size="small" @click="handleCommand($event, scope.row)">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              v-if="total != 0"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import picDetail from '../../../components/picDetail/picDetail'
import { dateFormat, setIndex, exportExcelNew } from "@/common/js/public.js";
import myComponent from "@/components/autocomplete/myautoComponent";
import InfoList from "../../../components/infoList/infoList";
export default {
  name: "roadOutEquipment",
  data() {
    return {
      flag: false,
      plateNumber: "",
      rowData: {},
      infoSize: 4,
      isShow: false,
      dialogVisible: false,
      infoDetail: {},
      radio3: "停车记录信息",
      isPublic: true,
      index: 0,
      total: 0,
      total1: 0,
      entryPic: [],
      exitPic: [],
      pageSize: 15,
      pageNum: 1,
      loading: false,
      detailFlag: false,
      areaList: [],
      areaList2: [],
      parkList: [],
      parkType: [
        {
          code: 1,
          desc: "地上",
        },
        {
          code: 2,
          desc: "地下",
        },
        {
          code: 3,
          desc: "地上+地下",
        },
      ],
      merchantList: [],
      payTypeList: [],
      berthList: [],
      tableOrderdata: [],
      ExitTypeList: [],
      roleList: [],
      tableCols: [
        {
          prop: "index",
          label: "序号",
          width: "70",
        },
        {
          prop: "parkName",
          label: this.$t("list.park_name"),
          width: "",
        },
        // {
        //   prop: "cityName",
        //   label: this.$t("list.region"),
        //   width: ""
        // },
        {
          prop: "areaName",
          label: this.$t("list.region"),
          width: "",
        },
        {
          prop: "operationName",
          label: this.$t("list.Merchant"),
          width: "",
        },
        {
          prop: "closedParkType",
          label: this.$t("list.Type_of_parking_lot"),
          width: "",
          formatter: (row, column) => {
            let type = {
              1: "地上",
              2: "地下",
              3: "地上+地下",
            };
            return type[row.closedParkType];
          },
        },
        {
          prop: "parkAddress",
          label: this.$t("list.address"),
          width: "",
        },
        {
          prop: "equipmentStatus",
          label: this.$t("list.is_malfunction"),
          width: "",
          formatter: (row, column) => {
            let status = {
              0: "是",
              1: "否",
            };
            return status[row.equipmentStatus];
          },
        },
      ],
      tableData: [],
      faultType: [
        {
          code: 0,
          desc: "是",
        },
        {
          code: 1,
          desc: "否",
        },
      ], // 故障车场
      parkDetail: [],
      parkRecordId: 0,
      parkRecordIdData: [],
      page: 1,
      size: 15,
      formInline: {
        areaId: "",
        streetId: "",
        operationId: "",
        parkId: "",
        closedParkType: "0",
        equipmentStatus: "",
      },
    };
  },
  methods: {
    handleCommand(cmd, data) {
      if (cmd == "a") {
        this.$router.push({
          path: "/roadOutEquipmentDetail",
          query: data,
        });
      }
    },
    resetForm() {
      this.$refs["searchWrapper"].clearValidate();
      this.formInline = {
        areaId: "",
        streetId: "",
        operationId: "",
        parkId: "",
        closedParkType: "0",
        equipmentStatus: "",
      };
      this.formInline.parkId = "";
      this.formInline.parkName = "";
      this.$refs.parkInput.setValue();
    },
    completeValue(item) {
      if (item) {
        this.formInline.parkId = item.parkId;
        this.formInline.parkName = item.parkName;
      } else {
        this.formInline.parkId = "";
        this.formInline.parkName = "";
      }
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      if (!this.flag) {
        this.searParkRecordList();
      }
    },
    areaChange(item) {
      this.$refs.parkInput.setValue("");
      this.formInline.operationId = "";
      if (!item) {
        this.formInline.streetId = "";
        this.areaList2 = [];
        return;
      }
      this.getChildrenArea(item);
    },
    getChildrenArea(item) {
      this.$axios
        .get("/acb/2.0/systems/loginUser/getChildrenArea", {
          data: {
            areaId: item,
          },
        })
        .then((res) => {
          this.areaList2 = res.value;
          this.formInline.streetId = "";
        });
    },
    // // 获取默认情况下的各种下拉列表
    // getParkSpinner() {
    //   this.$axios.get('/acb/2.0/park/spinner').then(res => {
    //     for (let i = 0; i < res.value.parkType.length; i++) {
    //       this.parkType.push(res.value.parkType[i]);
    //     }
    //   });
    // },
    // 初始化用户区域下拉列表
    getArea() {
      this.$axios.get("/acb/2.0/systems/loginUser/initAreaList").then((res) => {
        if (res.value.depth == 3) {
          this.areaList = res.value.areaList;
          this.formInline.areaId = "";
        } else if (res.value.depth == 4) {
          this.areaList2 = res.value.areaList;
          this.formInline.areaId = "";
        }
      });
    },
    /* 商户名称的下拉框 */
    getRoleList() {
      let opt = {
        method: "get",
        url: "/acb/2.0/parkRecord/operation/list",
        data: {
          page: 0,
          pageSize: 0,
          companyName: "",
          bearSide: "",
        },
        success: (res) => {
          this.loading = false;
          if (res.state == 0) {
            this.roleList = res.value;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
    searParkRecordListFun() {
      let opt = {
        method: "get",
        url: "/acb/2.0/thirdEquipment/parkList",
        data: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          areaId:
            this.formInline.streetId != "" ? this.formInline.streetId : this.formInline.areaId,
          parkId: this.formInline.parkId,
          operationId: this.formInline.operationId,
          parkType: this.formInline.parkType,
          closedParkType: this.formInline.closedParkType, // 封闭停车场物理类型
          equipmentStatus: this.formInline.equipmentStatus,
        },
        success: (res) => {
          this.loading = false;
          if (res.state == 0) {
            this.tableData = setIndex(this.pageNum, res.value.list);
            this.total = res.value.total * 1 || 0;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
    // 搜索
    searParkRecordList() {
      this.$refs["searchWrapper"].validate((v) => {
        if (v) {
          this.loading = true;
          this.flag = false;
          this.$refs.parkInput.setShowVal(this.formInline.parkName);
          this.searParkRecordListFun();
        }
      });
    },
  },
  components: {
    // InfoList,
    // picDetail,
    myComponent,
  },
  created() {
    this.getArea();
    this.getRoleList();
    // this.getExitType();
    // this.parkData()
    // this.getParkSpinner();
    if (this.$route.query.memberId) {
      //  this.formInline.startTime = this.$route.query.createdTime ? this.$route.query.createdTime : this.$route.query.bindTime ? this.$route.query.bindTime : dateFormat(new Date(), 'yyyy-MM-dd HH:mm:ss');
      this.formInline.startTime = this.$route.query.bindTime
        ? this.$route.query.bindTime
        : this.$route.query.createdTime
        ? this.$route.query.createdTime
        : dateFormat(new Date(), "yyyy-MM-dd HH:mm:ss");
      this.formInline.endTime = dateFormat(new Date(), "yyyy-MM-dd HH:mm:ss");
      this.date1 = this.formInline.startTime;
      this.date2 = this.formInline.endTime;
      if (this.$route.query.plateNumber) {
        this.plateNumber =
          this.$route.query.plateNumber.split(",").length > 1 ? "" : this.$route.query.plateNumber;
      }
      this.formInline.plateNumber = this.plateNumber;
      this.formInline.mobile = this.$route.query.mobile;
      this.flag = true;
      this.searParkRecordListFun();
    }
  },
  mounted() {},
  activated() {
    this.searParkRecordList();
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.searchWrapper
  overflow hidden
  padding 22px 22px 0
  margin-bottom 20px
</style>
