var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "info" }, [
    _c(
      "ul",
      _vm._l(_vm.infoData, function (item, index) {
        return _c(
          "li",
          {
            key: index,
            staticClass: "infoItem",
            style: { width: 100 / _vm.rols + "%" },
          },
          [
            _c("span", { staticClass: "label" }, [
              _vm._v(_vm._s(item.label) + "："),
            ]),
            _c("span", {
              staticClass: "value",
              style: item.style,
              domProps: {
                innerHTML: _vm._s(
                  item.formatter
                    ? item.formatter(_vm.infoDetail[item.value], _vm.infoDetail)
                    : _vm.infoDetail[item.value]
                ),
              },
            }),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }