<template>
  <div class="info">
    <ul>
      <li v-for="(item, index) in infoData"
          :key="index"
          class="infoItem"
          :style="{width: 100 / rols +'%'}">
        <span class="label">{{item.label}}：</span>
        <span class="value"
              :style="item.style"
              v-html="item.formatter?item.formatter(infoDetail[item.value], infoDetail):infoDetail[item.value]"></span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  computed: {
    rols () {
      let length = this.infoData.length;
      return Math.ceil(length / this.infoSize);
    }
  },
  props: {
    infoData: {
      default: {}
    },
    infoSize: {
      type: Number,
      default: 10
    },
    infoDetail: {
      default: {}
    }
  },
  mounted () {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.info
  font-size 12px
  color #313541
  letter-spacing 0
  line-height 32px
  .infoItem
    position relative
    padding-left 100px
    width 100%
    box-sizing border-box
    float left
    white-space nowrap
    height 32px
    .label
      position absolute
      left 0
      top 0
      width 100px
      text-align left
      display inline-block
      color #5E6D82
</style>
